<template>
  <v-navigation-drawer ref="drawer" class="navigation" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
    <div class="container-fluid">
      <v-card-actions
        v-if="
          checkViewOrEdit &&
          formData.created_at &&
          (checkDisabled2B ||
          checkDisabled2C ||
          checkDisabled2D) ||
          checkDisabled2E
        "
        class="d-flex justify-content-end"
      >
        <Button
          :disabled="checkDisabled2C || checkDisabled2D || checkDisabled2E"
          @click="isShowDialogDelete = true"
          color="error"
          :rounded="false"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
          {{ $t("common.cancel_reception") }}
        </Button>
      </v-card-actions>
      <div class="col-12 tab-eligible-person-information">
      <v-row>
        <v-col>
          <div class="col-12 border">
            <table v-if="medicalDirectives" class="table table-bordered">
              <tbody>
                <tr>
                  <td class="bg-gray width-180px">対象者ID</td>
                  <td class="width-250px">{{ String(medicalDirectives.id).padStart(10, "0") }}</td>
                  <td class="bg-gray width-180px">保健指導区分</td>
                  <td class="width-250px">{{ medicalDirectives.health_guidance_div }}</td>
                  <td class="bg-gray width-180px">利用券整理番号</td>
                  <td class="width-250px">{{ medicalDirectives.use_ticket_ref_number }}</td>
                </tr>
                <tr>
                  <td class="bg-gray width-180px">氏名カナ</td>
                  <td class="width-250px">{{ medicalDirectives.patient_name_kana }}</td>
                  <td class="bg-gray width-180px">氏名</td>
                  <td class="width-250px">{{ medicalDirectives.patient_name }}</td>
                  <td class="bg-gray width-180px">生年月日 / 性別</td>
                  <td class="width-250px">
                    {{ medicalDirectives.patient_birthday }} /
                    {{ medicalDirectives.patient_gender }}
                  </td>
                </tr>
                <tr>
                  <td class="bg-gray width-180px">保険者</td>
                  <td class="width-250px">
                    [{{ medicalDirectives.client_organ_code }}]
                    {{ medicalDirectives.client_organ_name }}
                  </td>
                  <td class="bg-gray width-180px">契約区分</td>
                  <td class="width-250px">{{ medicalDirectives.health_guidance_contract_div }}</td>
                  <td class="bg-gray width-180px">本人家族区分</td>
                  <td class="width-250px">{{ medicalDirectives.person_familiy_div }}</td>
                </tr>
                <tr>
                  <td class="bg-gray width-180px">保険証番号</td>
                  <td class="width-250px">{{ medicalDirectives.insured_card_number }}</td>
                  <td class="bg-gray width-180px">保険証記号</td>
                  <td class="width-250px">{{ medicalDirectives.insured_card_symbol }}</td>
                  <td class="bg-gray width-180px">保険証枝番</td>
                  <td class="width-250px">{{ medicalDirectives.insured_card_branch_code }}</td>
                </tr>
                <tr>
                  <td class="bg-gray width-180px">郵便番号</td>
                  <td class="width-250px">{{ medicalDirectives.patient_postal_code }}</td>
                  <td class="bg-gray width-180px">住所</td>
                  <td colspan="3">{{ medicalDirectives.patient_addr_01 }}</td>
                </tr>
                <tr>
                  <td colspan="6"></td>
                </tr>
                <tr>
                  <td class="bg-gray width-180px">受付状況</td>
                  <td class="width-250px">
                    <span v-if="medicalDirectives.health_guidance_no_target == 1"
                      >保健指導対象外</span
                    >
                    <span
                      v-if="
                        !formData.created_at &&
                        medicalDirectives.health_guidance_no_target == 0
                      "
                      >未受付</span
                    >
                    <span
                      v-if="
                        formData.created_at &&
                        medicalDirectives.health_guidance_no_target == 0
                      "
                      >受付済</span
                    >
                  </td>
                  <td class="bg-gray width-180px">支援状況</td>
                  <td class="width-250px ">
                    <span v-if="medicalDirectives.health_guidance_no_target == 1"
                      >ー</span
                    >
                    <span v-else>{{ medicalDirectives.codeSupportStatus }}</span>
                  </td>
                  <td class="bg-gray width-180px">支援状況詳細</td>
                  <td class="width-250px">
                    <span v-if="medicalDirectives.health_guidance_no_target == 1"
                      >ー</span
                    >
                    <span v-else
                      >{{ medicalDirectives.codeSupportExecStatus }}
                      {{ medicalDirectives.codeSupportProgressStatus }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <div class="d-flex justify-content-between">
          <div>
            <label for="date-time">
              {{ $t("receiptMedical.reception_date") }}
            </label>
            <span class="required"></span>
            <date-picker
              autocomplete="off"
              v-model="formData.reception_date"
              id="reception_date"
              name="reception_date"
              :config="reception_date"
              :disabled="checkDisabled2E || !checkViewOrEdit"
            ></date-picker>
            <span class="text-danger">{{ errorsFormData?.reception_date }}</span>
          </div>
          <div>
            <div class="d-flex">
              <div style="width: 260px">登録情報</div>
              <div style="width: 260px">更新情報</div>
            </div>
            <div
              v-if="medicalDirectives"
              class="container-info-datetime d-flex justify-content-between"
            >
              <Sheet width="260" height="40" class="p-2">
                {{ formData.created_at }}
                <span v-if="formData.created_at && formData.created_by">
                  by
                </span>
                {{ formData.created_by }}
              </Sheet>
              <Sheet width="260" height="40" class="p-2">
                {{ formData.updated_at }}
                <span v-if="formData.updated_at && formData.updated_by">
                  by
                </span>
                {{ formData.updated_by }}
              </Sheet>
            </div>
          </div>
        </div>
      </v-row>

      <v-row v-if="!checkDisabled2E">
        <v-col>
          <Select
            v-if="checkViewOrEdit"
            :items="listContract"
            v-model="formData.contract"
            solo
            :disabled="checkDisabled2E || !checkViewOrEdit"
          />
        </v-col>
        <v-col>
          <Button
            v-if="checkViewOrEdit"
            color="success"
            @click="confirmCopyContract()"
            :rounded="false"
            :disabled="checkDisabled2E"
          >
            {{ $t("receiptMedical.copy_contract") }}
          </Button>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label for="contract_name">
            {{ $t("receiptMedical.contract_name") }}
            <span class="required"></span>
          </label>
          <InputCommon
            id="contract_name"
            v-model="formData.contract_name"
            solo
            autocomplete="off"
            :filled="false"
            :rounded="false"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            :error-messages="errorsFormData.contract_name"
          />
        </v-col>
        <v-col>
          <label for="out_of_support_patient_ref_level" class="mb-3">
            支援担当外 参照可能レベル
            <span class="required"></span>
          </label>
          <Select
            id="out_of_support_patient_ref_level"
            :items="out_of_support_patient_ref_level_arr"
            v-model="formData.out_of_support_patient_ref_level"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            solo
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label for="year">
            {{ $t("common.year") }}
            <span class="required"></span>
          </label>
          <!-- <Select
            id="year"
            :items="listYear"
            v-model="formData.contract_fiscal_year"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            :error-messages="errorsFormData.contract_fiscal_year"
            solo
          /> -->
          <date-picker
                  autocomplete="off"
                  v-model="formData.contract_fiscal_year"
                  id="contract_fiscal_year"
                  name="contract_fiscal_year"
                  :config="yearOptions"
                  :disabled="checkDisabled2E || !checkViewOrEdit"
                  @input="onHandleChangeContractFiscalYear"
          ></date-picker>
          <span class="text-danger">{{ errorsFormData.contract_fiscal_year }}</span>
        </v-col>
        <v-col>
            <label for="period">
                {{ $t('common.period') }}
            </label>
            <Select
            solo
                id="period"
                v-model="formData.health_guidance_period"
                :items="periodOptions"
                :disabled="checkDisabled2E || !checkViewOrEdit || !isPeriodEditable"
                :error-messages="errorsFormData.health_guidance_period"
            />
        </v-col>
        <v-col>
          <label for="code_contract">
            {{ $t("receiptMedical.table.code_contract") }}
            <span class="required"></span>
          </label>
          <Select
            id="code_contract"
            :items="listContractCode"
            v-model="formData.health_guidance_contract_div"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            :error-messages="errorsFormData.health_guidance_contract_div"
            solo
          />
        </v-col>
        <v-col>
          <label for="health_guidance_code">
            {{ $t("receiptMedical.table.health_guidance_code") }}
            <span class="required"></span>
          </label>
          <Select
            id="health_guidance_code"
            :items="listHealthCode"
            v-model="formData.health_guidance_div"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            solo
            :error-messages="errorsFormData.health_guidance_div"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label for="family_code">
            {{ $t("receiptMedical.family_code") }}
            <span class="required"></span>
          </label>

          <div class="form-check mt-4 d-flex flex-column">
            <div class="d-flex">
              <div
                  v-for="(item, index) in listFamilyCode"
                  :key="item.value + index + 'person_familiy_div'"
              >
                <input
                    :disabled="checkDisabled2E || !checkViewOrEdit"
                    class="form-check-input"
                    v-model="formData.person_familiy_div"
                    :value="item.value"
                    type="radio"
                    :id="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                />
                <label
                    class="mr-8 form-check-label"
                    :for="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>

            <div class="messageErrorCustom" :class="{ showMessageCustom: errorsFormData.person_familiy_div}">
              <span>{{errorsFormData.person_familiy_div}}</span>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label for="support_pattern">
            {{ $t("receiptMedical.support_pattern") }}
            <span class="required"></span>
          </label>
          <Select
            id="support_pattern"
            :items="listSupportPattern"
            v-model="formData.health_support_pattern_id"
            :disabled="checkDisabled2E || !checkViewOrEdit"
            :error-messages="errorsFormData.health_support_pattern_id"
            solo
          />
        </v-col>
        <v-col> </v-col>
      </v-row>

    <v-row>
      <v-col>
        <label for="initial_interview_format">
          {{ $t("receiptMedical.initial_interview_format") }}
          <span class="required"></span>
        </label>
        <Select
          id="initial_interview_format"
          :items="listStatusInitialPreview"
          v-model="formData.first_support_form_id"
          :error-messages="errorsFormData.first_support_form_id"
          :disabled="
            checkDisabled2E ||
            checkDisabled2C ||
            checkDisabled2D ||
            !checkViewOrEdit
          "
          solo
          class="mt-3"
        />
      </v-col>
      <v-col>
        <label for="time_support">
          {{ $t("receiptMedical.time_support") }}
        </label>
        <InputCommon
          id="time_support"
          name="continued_support_term"
          :autocomplete="'off'"
          :type="'number'"
          :disabled="!checkViewOrEdit"
          :filled="false"
          :error-messages="errorsFormData.continued_support_term"
          v-model="formData.continued_support_term"
          :rounded="false"
          solo
        />
      </v-col>
    </v-row>

      <v-row>
        <v-col>
          <label for="health_guidance_agency">
            対象者入力フォームレイアウト
          </label>
          <Select
            id="health_guidance_agency"
            :disabled="
              checkDisabled2E ||
              !checkViewOrEdit"
            :items="client_organization_form_layout_control_arr"
            v-model="formData.client_organization_form_layout_control_id"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row>
        <v-col>
          <label for="health_guidance_agency">
            {{ $t("receiptMedical.health_guidance_agency") }}
            <span class="required"></span>
          </label>
          <Select
            id="health_guidance_agency"
            :disabled="!checkViewOrEdit"
            :items="listHealthAgency"
            v-model="formData.health_guidance_organization_id"
            :error-messages="errorsFormData.health_guidance_organization_id"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row>
        <v-col>
          <label for="payment_agency">
            {{ $t("receiptMedical.payment_agency") }}
          </label>
          <Select
            :disabled="!checkViewOrEdit"
            id="payment_agency"
            :items="listPaymentAgency"
            v-model="formData.payment_agency_id"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row>
        <v-col>
          <label for="contract_agency">
            {{ $t("receiptMedical.contract_agency") }}
          </label>
          <Select
            id="contract_agency"
            :disabled="!checkViewOrEdit"
            :items="listContractAgency"
            v-model="formData.contract_coord_organization_id"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row>
        <v-col>
          <label for="destination_institution">
            {{ $t("receiptMedical.destination_institution") }}
          </label>
          <Select
            id="destination_institution"
            :disabled="!checkViewOrEdit"
            :items="listDestinationInstitution"
            v-model="formData.addressee_organiation_id"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row class="d-flex">
        <v-col>
          <label for="rounded_division">
            {{ $t("receiptMedical.rounded_division") }}
            <span class="required"></span>
          </label>
          <Select
            id="rounded_division"
            :disabled="!checkViewOrEdit"
            :items="listfractionating_div"
            v-model="formData.fractionating_div"
            :error-messages="errorsFormData.fractionating_div"
            solo
            class="mt-3"
          />
        </v-col>
        <v-col>
          <label for="contract_unit_price_xml">
            {{ $t("receiptMedical.contract_unit_price_xml") }}
            <span class="required"></span>
          </label>
          <InputCommon
            id="contract_unit_price_xml"
            name="contract_unit_price_01"
            type="number"
            :disabled="!checkViewOrEdit"
            v-model="formData.contract_unit_price_01"
            :error-messages="errorsFormData.contract_unit_price_01"
            solo
            :filled="false"
            :rounded="false"
            suffix="円"
            @change="onFormatDataNumber"
          />
        </v-col>
        <v-col>
          <label for="contract_unit_price_billing">
            {{ $t("receiptMedical.contract_unit_price_billing") }}
            <span class="required"></span>
          </label>
          <InputCommon
            id="contract_unit_price_billing"
            type="number"
            name="contract_unit_price_02"
            :disabled="!checkViewOrEdit"
            v-model="formData.contract_unit_price_02"
            :error-messages="errorsFormData.contract_unit_price_02"
            solo
            :filled="false"
            :rounded="false"
            suffix="円"
          />
        </v-col>
        <v-col />
      </v-row>

      <v-row>
        <v-col>
          <label for="payment_setting">
            {{ $t("receiptMedical.payment_setting") }}
          </label>
          <v-card class="mt-3">
            <v-container class="d-flex justify-content-between">
              <div>
                <label for="start">
                  {{ $t("common.start") }}
                  <span class="required"></span>
                </label>
                <InputCommon
                  id="start"
                  type="number"
                  :disabled="!checkViewOrEdit"
                  :rounded="false"
                  :filled="false"
                  name="start_pyament_rate"
                  v-model="formData.start_pyament_rate"
                  :error-messages="errorsFormData.start_pyament_rate"
                  solo
                  suffix="%"
                />
              </div>
              <div>
                <label for="finish">
                  {{ $t("common.finish") }}
                  <span class="required"></span>
                </label>
                <InputCommon
                  id="finish"
                  type="number"
                  :disabled="!checkViewOrEdit"
                  :rounded="false"
                  :filled="false"
                  name="final_eval_payment_rate"
                  v-model="formData.final_eval_payment_rate"
                  :error-messages="errorsFormData.final_eval_payment_rate"
                  solo
                  suffix="%"
                />
              </div>
              <div>
                <label for="halfway_finish">
                  {{ $t("common.halfway_finish") }}
                  <span class="required"></span>
                </label>
                <InputCommon
                  id="halfway_finish"
                  type="number"
                  name="retire_payment_rate"
                  :disabled="!checkViewOrEdit"
                  :rounded="false"
                  :filled="false"
                  v-model="formData.retire_payment_rate"
                  :error-messages="errorsFormData.retire_payment_rate"
                  solo
                  suffix="%"
                />
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

    </div>
    <v-card-actions class="d-flex justify-content-between">
      <div>
        <Button @click="hide" color="default" :rounded="false">
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("common.cancel") }}
        </Button>
      </div>
      <div v-if="checkViewOrEdit" class="d-flex justify-content-around gap-5">
        <div v-if="!checkDisabled2A && formData.created_at && medicalDirectives.health_guidance_no_target == 0" class="form-check mt-3">
          <label class="form-check-label" for="create_flow_support">
            {{
              $t("receiptMedical.receiptMedicalModal.recreate_assistance")
            }}</label
          >
          <input
            v-model="formData.create_flow_support"
            type="checkbox"
            class="form-check-input"
            id="create_flow_support"
            ref="create_flow_support"
            :disabled="checkDisabled2E || checkDisabled2D"
          />
        </div>
        <Button @click="isShowDialogSave = true" color="info" :rounded="false">
          <v-icon>mdi-database</v-icon> {{ $t("common.keep") }}
        </Button>
      </div>
    </v-card-actions>
    <Dialog :maxWidth="600" :isShow="isShowDialogDelete">
      <v-card>
        <v-container>
          <v-card-title> 削除 </v-card-title>
          <div class="m-3">受付取消処理を実行します。よろしいですか？</div>
          <v-card-actions
            class="d-flex align-content-center justify-content-between mr-2 ml-2"
          >
            <button
              type="button"
              class="btn btn-secondary"
              @click="isShowDialogDelete = false"
            >
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="checkReception('delete')">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :isShow="isDialogCopy" persistent :maxWidth="600">
      <DialogCopyMedicalReception @confirmDialog="copyContract" @closeDialog="onHandleCloseDialog" :copyItem="copyItem"/>
    </Dialog>
    <Dialog :maxWidth="600" :isShow="isShowDialogSave" persistent>
      <v-card>
        <v-container>
          <v-card-title>
            確認
          </v-card-title>
          <div class="m-3">
            保存処理を実行します。よろしいですか？<br>
            {{ formData.create_flow_support ? "支援フローが再作成により、継続支援及び最終評価の支援内容が削除されます。" : ""}}
          </div>
          <v-card-actions class=" d-flex align-content-center justify-content-between mr-2 ml-2">
            <button
              type="button"
              class="btn btn-secondary"
              @click="isShowDialogSave = false">
              キャンセル
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="checkReception('save')">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
  </div>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import Button from "@/components/common/Button";
import Sheet from "@/components/common/Sheet";
import Select from "@/components/common/Select";
import InputCommon from "@/components/common/InputCommon";
import moment from "moment";
import MedicalDesignatedSubjects from "@/services/api/MedicalDesignatedSubjects";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { formCreateOrEditReceiptMedical } from "../../../common/schemaValidation";
import Dialog from "@/components/common/Dialog";
import DialogCopyMedicalReception from "@/components/MedicalDesignateReception/DialogCopyMedicalReception";
import mixins from "@/mixins";
import { CODE_GENDER, OUT_OF_SUPPORT_PATIENT_REF_LEVEL, TOAST_TIME_OUT } from "@/common/constants";
import _ from "lodash";

export default {
    name: "ReceiptMedical",
    mixins: [mixins],
    components: {
        InputCommon,
        Select,
        Sheet,
        Button,
        Dialog,
        DialogCopyMedicalReception
    },
    props: {
        dataDetailId: {
            default: "",
        },
        dataReceptionsId: {
            default: null,
        },
        checkViewOrEdit: {
            default: false,
        },
        isShowNavigation: {
            default: "",
        },
    },
    data() {
        return {
            isShowDialogDelete: false,
            listContract: [],
            listYear: this.getFiscalYearFromDateArr(true),
            listContractCode: [],
            listHealthCode: [],
            listStatusInitialPreview: [],
            listfractionating_div: [],
            listDestinationInstitution: [],
            listContractAgency: [],
            listSupportPattern: [],
            medicalDirectives: null,
            listPaymentAgency: [],
            listHealthAgency: [],
            listFamilyCode: [],
            formData: {
                create_flow_support: false,
                contract_fiscal_year: "",
                health_guidance_period: null,
                continued_support_term: "",
                fractionating_div: "",
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                contract: "",
                contract_name: "",
                health_guidance_contract_div: "",
                reception_date: null,
                addressee_organiation_id: "",
                contract_coord_organization_id: "",
                health_guidance_div: "",
                health_support_pattern_id: "",
                payment_agency_id: "",
                health_guidance_organization_id: "",
                person_familiy_div: "",
                first_support_form_id: "",
                created_at: "",
                updated_at: "",
                created_by: "",
                updated_by: "",
                out_of_support_patient_ref_level: 0,
                client_organization_form_layout_control_id: null,
            },
            health_guidance_receptions_id: null,
            checkDisabled2C: false,
            checkDisabled2D: false,
            checkDisabled2E: false,
            checkDisabled2A: false,
            checkDisabled2B: false,
            errorsFormData: {
                continued_support_term: "",
            },
            rulesValidate: undefined,
            code_gender: [],
            checkUpdateData: false,
            isDialogCopy: false,
            out_of_support_patient_ref_level_arr: [],
            client_organization_form_layout_control_arr: [],
            insurer_id: null,
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
            isShowDialogSave: false,
        };
    },
    watch: {
        dataDetailId() {
            if(this.dataDetailId){
                this.medicalDirectives = null;
                this.formData = {
                    create_flow_support: false,
                    contract_fiscal_year: "",
                    health_guidance_period: null,
                    continued_support_term: "",
                    fractionating_div: 1,
                    contract_unit_price_01: 0,
                    contract_unit_price_02: 0,
                    start_pyament_rate: 0,
                    final_eval_payment_rate: 0,
                    retire_payment_rate: 0,
                    contract: null,
                    contract_name: "",
                    health_guidance_contract_div: "",
                    reception_date: null,
                    addressee_organiation_id: "",
                    contract_coord_organization_id: "",
                    health_guidance_div: "",
                    health_support_pattern_id: "",
                    payment_agency_id: "",
                    health_guidance_organization_id: "",
                    person_familiy_div: "",
                    first_support_form_id: "",
                    created_at: "",
                    updated_at: "",
                    created_by: "",
                    updated_by: "",
                    out_of_support_patient_ref_level: 0,
                    client_organization_form_layout_control_id: null,
                };
                this.health_guidance_receptions_id = this.dataReceptionsId;
                this.setupInitialSelect();
                this.setupMedicalDirectives();
            }
        },
        dataReceptionsId() {
            if(this.dataReceptionsId){
                this.health_guidance_receptions_id = this.dataReceptionsId;
                this.medicalDirectives = null;
                this.setupInitialSelect();
                this.setupMedicalDirectives();
            }
        },
        insurer_id() {
            MedicalDesignatedSubjects.getClientOrganizationFormLayoutControl(this.insurer_id).then(
                (res) => {
                    this.client_organization_form_layout_control_arr = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.client_organization_form_layout_control_arr.push({
                            text: x.layout_name,
                            value: x.id,
                        });
                    });
                }
            );
        },
        checkViewOrEdit() {
            // console.log(this.checkViewOrEdit);
        },
        medicalDirectives() {
            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        },
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },
    async created() {
        const out_of_support_patient_ref_level_arr = await this.getCodeDefine(OUT_OF_SUPPORT_PATIENT_REF_LEVEL);
        for (const [key, value] of Object.entries(out_of_support_patient_ref_level_arr)) {
            this.out_of_support_patient_ref_level_arr.push({
                text: value,
                value: parseInt(key),
            });
        };
        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        this.health_guidance_receptions_id = this.dataReceptionsId;
        this.rulesValidate = formCreateOrEditReceiptMedical;
        await this.setupInitialSelect();
        await this.setupMedicalDirectives();
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    computed: {
        ...mapState({
            slideDisplay: "slideDisplay",
        }),
        ...mapGetters({
            getCheckUpdateHealthGuidanceReceptions: "common/getCheckUpdateHealthGuidanceReceptions"
        }),
        copyItem() {
            if (!this.formData.contract) return null
            return this.listContract.filter(v => v.value === this.formData.contract)[0]
        },
        reception_date() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        yearOptions() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle"
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            }
        },
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        },
        // 期の選択肢
        periodOptions() {
            return this.getHealthGuidancePeriods(this.formData.contract_fiscal_year).map((v) => ({
                text: `第${v}期`,
                value: v,
            }));
        },
        // 期の切り替え可能な年度
        isPeriodEditable() {
            if (!this.formData.contract_fiscal_year) {
                return false
            }
            return this.getPeriodSwitchingYears().includes(Number(this.formData.contract_fiscal_year));
        }
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            setCheckUpdateHealthGuidanceReceptions: "common/setCheckUpdateHealthGuidanceReceptions"
        }),
        onFormatDataNumber(e) {
            // const {name, value} = e.target
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(
                ".v-navigation-drawer__border"
            );
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },
        onHandleChange(field, value) {
            if (value === undefined || value === null) return;
            this.formData[field] = value;
            this.validate(field);
        },
        validate(field) {
            formCreateOrEditReceiptMedical
                .validateAt(field, this.formData)
                .then(() => {
                    this.errorsFormData[field] = "";
                })
                .catch((err) => {
                    this.errorsFormData[field] = err.message;
                });
        },
        save() {
            formCreateOrEditReceiptMedical
                .validate(this.formData, { abortEarly: false })
                .then(() => {
                    this.errorsFormData = {};
                    this.setIsLoading(true);
                    this.formData.create_flow_supports = [];
                    if (
                        this.formData.create_flow_support &&
                        this.health_guidance_receptions_id
                    ) {
                        this.formData.create_flow_supports = [
                            this.health_guidance_receptions_id,
                        ];
                    }
                    if (this.health_guidance_receptions_id) {
                        this.formData.health_guidance_reception_ids = [
                            this.health_guidance_receptions_id,
                        ];
                        delete this.formData.patient_ids;
                    } else {
                        this.formData.patient_ids = [this.dataDetailId];
                    }
                    if(!this.formData.reception_date){
                        this.formData.reception_date = new Date();
                    }

                    MedicalDesignatedSubjects.save(this.formData).then((res) => {
	                    this.setIsLoading(false);
                        if (res.status != SUCCESS) {
	                        this.$toast.error(`[${res.error?.messageID}] ${res.error?.message}`);
	                        return;
                        }
                        if (res.data.data[0].status) {
                            this.checkUpdateData = true;
                            this.health_guidance_receptions_id =
                                res.data.data[0].health_guidance_reception_id;
                            this.$toast.open({
                                message: "保健指導受付の登録が完了しました。",
                                type: "success",
                            });
                            this.setupMedicalDirectives();
                        } else {
                            this.checkUpdateData = false;
                            this.$toast.open({
                                message: "保健指導受付処理中にエラーが発生しました。",
                                type: "error",
                            });
                        }
                    });
                })
                .catch((err) => {
                    let listError = {}
                    err.inner.forEach((error) => {
                        listError[error.path] = error.message;
                    });
                    this.errorsFormData = listError
                });
        },
        hide() {
            $(".tab-eligible-person-information").scrollTop(0);
            this.hiddenAnimation = true;

            if (this.checkUpdateData) {
                this.checkUpdateData = false;
                this.$emit("reload", this.formData, "update");
            } else {
                this.$emit("reload", this.formData, "hidden");
            }

            (this.errorsFormData = {
                continued_support_term: "",
            }),
            this.setupInitialSelect();
            this.$store.commit("hideSlide");
        },
        remove() {
            this.isShowDialogDelete = false;
            MedicalDesignatedSubjects.delete(this.health_guidance_receptions_id).then(
                () => {
                    this.setIsLoading(false);
                    this.$toast.open({
                        message: "保健指導 受付取消の登録が完了しました。",
                        type: "success",
                    });
                    this.$emit("reload", this.health_guidance_receptions_id, "delete");
                    this.checkDisabled2B = false;
                    this.formData = {
                        create_flow_support: false,
                        contract_fiscal_year: "", 
                        health_guidance_period: null,
                        continued_support_term: "",
                        fractionating_div: 1,
                        contract_unit_price_01: 0,
                        contract_unit_price_02: 0,
                        start_pyament_rate: 0,
                        final_eval_payment_rate: 0,
                        retire_payment_rate: 0,
                        contract: null,
                        contract_name: "",
                        health_guidance_contract_div: "",
                        reception_date: null,
                        addressee_organiation_id: "",
                        contract_coord_organization_id: "",
                        health_guidance_div: "",
                        health_support_pattern_id: "",
                        payment_agency_id: "",
                        health_guidance_organization_id: "",
                        person_familiy_div: "",
                        first_support_form_id: "",
                        created_at: "",
                        updated_at: "",
                        created_by: "",
                        updated_by: "",
                        out_of_support_patient_ref_level: 0,
                        client_organization_form_layout_control_id: null,
                    };
                    this.health_guidance_receptions_id = null;
                    this.$store.commit("hideSlide");
                }
            );
        },
        confirmCopyContract() {
            if (!this.formData.contract || !this.copyItem) return
            this.isDialogCopy = true;
        },
        onHandleCloseDialog() {
            this.isDialogCopy = false;
        },
        copyContract() {
            if(!this.formData.contract){
                const default_health_guidance_organization_id = this.listHealthAgency.filter(x => x.default_flag == 1);
                if(default_health_guidance_organization_id.length > 0){
                    this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].value;
                }
                return;
            }
            this.setIsLoading(true);
            this.isDialogCopy = false;
            MedicalDesignatedSubjects.getContract(this.formData.contract).then(
                (res) => {
                    this.setIsLoading(false);
                    const { data, status } = res;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) return;


                    this.formData.contract_fiscal_year = String(data.data.contract_fiscal_year),
                    this.formData.health_guidance_period = data.data.health_guidance_period,
                    this.formData.continued_support_term = data.data.continued_support_term,
                    this.formData.fractionating_div = parseInt(data.data.fractionating_div),
                    this.formData.contract_unit_price_01 = parseInt(data.data.contract_unit_price_01 || 0),
                    this.formData.contract_unit_price_02 = parseInt(data.data.contract_unit_price_02 || 0),
                    this.formData.start_pyament_rate = parseInt(data.data.start_pyament_rate || 0),
                    this.formData.final_eval_payment_rate = parseInt(data.data.final_eval_payment_rate || 0),
                    this.formData.retire_payment_rate = parseInt(data.data.retire_payment_rate || 0),
                    this.formData.contract = data.data.id,
                    this.formData.contract_name = data.data.contract_name,
                    this.formData.health_guidance_contract_div = parseInt(data.data.health_guidance_contract_div) ?? "",
                    this.formData.addressee_organiation_id = data.data.addressee_organiation_id,
                    this.formData.contract_coord_organization_id = data.data.contract_coord_organization_id,
                    this.formData.health_guidance_div = parseInt(data.data.health_guidance_div),
                    this.formData.health_support_pattern_id = data.data.health_support_pattern_id,
                    this.formData.payment_agency_id = data.data.payment_agency_id,
                    this.formData.person_familiy_div = data.data.person_familiy_div,
                    this.formData.health_guidance_organization_id = data.data.health_guidance_organization_id,
                    this.formData.first_support_form_id = data.data.first_support_form_id

                    const default_health_guidance_organization_id = this.listHealthAgency.filter(x => x.default_flag == 1);
                    if(default_health_guidance_organization_id.length > 0 && !data.data.health_guidance_organization_id && data.data.health_guidance_organization_id != undefined ){
                        this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].value;
                    }
                    this.$toast.open({
                        message: "契約情報コピー処理が正常終了しました。",
                        type: "success",
                    });
                }
            );
        },
        getHealthGuidanceDiv(code) {
            const value = this.listHealthCode.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        getFamilyCode(person_familiy_div) {
            if (person_familiy_div && person_familiy_div != undefined) {
                let familyCode = this.listFamilyCode.filter(
                    (x) => x.value == person_familiy_div
                );
                if (familyCode.length > 0) {
                    return familyCode[0].text;
                }
            }
            return null;
        },
        setupMedicalDirectives() {
            this.checkDisabled2C = false;
            this.checkDisabled2D = false;
            this.checkDisabled2E = false;
            this.checkDisabled2A = false;
            this.checkDisabled2B = false;
            if (!this.health_guidance_receptions_id) {
                MedicalDesignatedSubjects.getPatient(this.dataDetailId).then((res) => {
                    const { data, status } = res;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) return;
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    this.insurer_id = data.data.insurer_id;
                    MedicalDesignatedSubjects.getContractClient(
                        data.data?.insurer_id,
                        {
                            "health_guidance_contract_div" : data.data?.health_guidance_contract_div,
                            "person_familiy_div" : data.data?.person_familiy_div,
                            "health_guidance_div" : data.data?.health_guidance_div,
                        }
                    ).then((res) => {
                        this.listContract = [
                            {
                                text: "",
                                value: null,
                            },
                        ];
                        res.data.data.map((x) => {
                            this.listContract.push({
                                text: x.contract_name,
                                value: parseInt(x.id),
                            });
                        });
                    });
                    this.medicalDirectives = {
                        id: data.data.id,
                        health_guidance_no_target: data.data.health_guidance_no_target,
                        health_guidance_div: this.getHealthGuidanceDiv(
                            data.data.health_guidance_div
                        ),
                        use_ticket_ref_number: data.data.use_ticket_ref_number,
                        patient_name_kana: data.data.patient_name_kana,
                        patient_name: data.data.patient_name,
                        patient_birthday: data.data.patient_birthday,
                        patient_gender: this.code_gender[data.data.patient_gender],
                        client_organ_code: data.data.client_organizations?.client_organ_code,
                        client_organ_name: data.data.client_organizations?.client_organ_name,
                        person_familiy_div: this.getFamilyCode(
                            data.data.person_familiy_div
                        ),
                        health_guidance_contract_div: this.getHealthGuidanceContract(
                            data.data.health_guidance_contract_div
                        ),
                        insured_card_number: data.data.insured_card_number,
                        insured_card_symbol: data.data.insured_card_symbol,
                        insured_card_branch_code: data.data.insured_card_branch_code,
                        patient_postal_code: data.data.patient_postal_code,
                        patient_addr_01: data.data.patient_addr_01,
                        created_at: moment(data.data.created_at).format("YYYY-MM-DD") + " ",
                        created_by: data.data.created_by
                            ? data.data.created_by.user_name
                            : null,
                        updated_at: moment(data.data.updated_at).format("YYYY-MM-DD") + " ",
                        updated_by: data.data.updated_by
                            ? data.data.updated_by.user_name
                            : null,
                        codeSupportStatus: "ー",
                        codeSupportExecStatus: "ー",
                        codeSupportProgressStatus: null,
                    };
                });
                this.setCheckUpdateHealthGuidanceReceptions(
                    {
                        checkDisabled2C: this.checkDisabled2C,
                        checkDisabled2D: this.checkDisabled2D,
                        checkDisabled2E: this.checkDisabled2E,
                        checkDisabled2A: this.checkDisabled2A,
                        checkDisabled2B: this.checkDisabled2B,
                    })
            } else {
                MedicalDesignatedSubjects.getReceiptMedical(
                    this.health_guidance_receptions_id
                ).then((res) => {
                    const { data, status } = res;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) return;

                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });

                    this.insurer_id = data.data.patients.insurer_id;
                    MedicalDesignatedSubjects.getContractClient(
                        data.data.patients.insurer_id, {
                            "health_guidance_contract_div" : data.data.patients.health_guidance_contract_div,
                            "person_familiy_div" : data.data.patients.person_familiy_div,
                            "health_guidance_div" : data.data.patients.health_guidance_div,
                        }
                    ).then((res) => {
                        this.listContract = [];
                        res.data.data.map((x) => {
                            this.listContract.push({
                                text: x.contract_name,
                                value: x.id,
                            });
                        });
                    });

                    this.medicalDirectives = {
                        health_guidance_no_target:
                        data.data.patients.health_guidance_no_target,
                        id: data.data.patients.id,
                        health_guidance_div: this.getHealthGuidanceDiv(
                            parseInt(data.data.patients.health_guidance_div)
                        ),
                        use_ticket_ref_number: data.data.patients.use_ticket_ref_number,
                        patient_name_kana: data.data.patients.patient_name_kana,
                        patient_name: data.data.patients.patient_name,
                        patient_birthday: data.data.patients.patient_birthday,
                        patient_gender: this.code_gender[data.data.patients.patient_gender],
                        client_organ_code:
                        data.data.patients.client_organizations?.client_organ_code,
                        client_organ_name:
                        data.data.patients.client_organizations?.client_organ_name,
                        person_familiy_div: this.getFamilyCode(
                            data.data.patients.person_familiy_div
                        ),
                        health_guidance_contract_div: this.getHealthGuidanceContract(
                            data.data.patients.health_guidance_contract_div
                        ),
                        insured_card_number: data.data.patients.insured_card_number,
                        insured_card_symbol: data.data.patients.insured_card_symbol,
                        insured_card_branch_code:
                        data.data.patients.insured_card_branch_code,
                        patient_postal_code: data.data.patients.patient_postal_code,
                        patient_addr_01: data.data.patients.patient_addr_01,
                        created_at:
                        moment(data.data.created_at).format("YYYY-MM-DD HH:mm:ss") + " ",
                        created_by: data.data.created_by
                            ? data.data.created_by.user_name
                            : null,
                        updated_at:
                        moment(data.data.updated_at).format("YYYY-MM-DD HH:mm:ss") + " ",
                        updated_by: data.data.updated_by
                            ? data.data.updated_by.user_name
                            : null,
                        codeSupportStatus: "ー",
                        codeSupportExecStatus: "ー",
                        codeSupportProgressStatus: null,
                    };

                    const reception = data.data;

                    if (reception.health_support_flows) {
                        const health_support_flows = reception.health_support_flows[0];

                        MedicalDesignatedSubjects.getCodeSupportStatus().then((res) => {
                            for (const [key, value] of Object.entries(res.data)) {
                                if (health_support_flows.support_flow_status == key) {
                                    this.medicalDirectives.codeSupportStatus = value;
                                }
                            }

                        });
                        if (health_support_flows.health_support_flow_segments) {
                            const dataHealthFlowSegment = _.sortBy(
                                health_support_flows.health_support_flow_segments,
                                "support_flow_seq"
                            );
                            var itemHealthFlowSegment = null;

                            var count = 0;

                            for (let i = 0; i < dataHealthFlowSegment.length; i++) {
                                if (dataHealthFlowSegment[i].support_exec_div == 20) {
                                    count++;
                                }
                                if (
                                    dataHealthFlowSegment[i].support_progress_status == 10 ||
                                    dataHealthFlowSegment[i].support_progress_status == 20 ||
                                    dataHealthFlowSegment[i].support_progress_status == 30
                                ) {
                                    itemHealthFlowSegment = dataHealthFlowSegment[i];
                                    break;
                                }
                            }

                            if(health_support_flows.support_flow_status != 30 && health_support_flows.support_flow_status != 40 && health_support_flows.support_flow_status != 50){
                                MedicalDesignatedSubjects.getCodeSupportExecStatus().then(
                                    (res) => {
                                        for (const [key, value] of Object.entries(res.data)) {
                                            if (itemHealthFlowSegment.support_exec_div == key) {
                                                let checkCount = count != 0 ? ` (${count} 回目) ` : " ";
                                                this.medicalDirectives.codeSupportExecStatus = value;
                                                if (key == 20) {
                                                    this.medicalDirectives.codeSupportExecStatus = value + checkCount;
                                                }
                                            }
                                        }
                                    }
                                );
                                MedicalDesignatedSubjects.getCodeSupportProgressStatus().then(
                                    (res) => {
                                        for (const [key, value] of Object.entries(res.data)) {
                                            if (itemHealthFlowSegment.support_progress_status == key) {
                                                this.medicalDirectives.codeSupportProgressStatus = value;
                                            }
                                        }
                                    }
                                );
                            }


                            if (health_support_flows.support_flow_status == 30) {
                                this.checkDisabled2A = true;
                            }

                            if (
                                health_support_flows.support_flow_status == 30 ||
                                health_support_flows.support_flow_status == 40 ||
                                health_support_flows.support_flow_status == 50
                            ) {
                                this.checkDisabled2E = true;
                            }

                            for (let i = 0; i < dataHealthFlowSegment.length; i++) {
                                if (
                                    health_support_flows.support_flow_status == 10 &&
                                dataHealthFlowSegment[i].support_exec_div == 10 &&
                                dataHealthFlowSegment[i].support_progress_status == 10
                                ) {
                                    this.checkDisabled2B = true;
                                }

                                if (
                                    health_support_flows.support_flow_status == 10 &&
                                dataHealthFlowSegment[i].support_exec_div == 10 &&
                                (dataHealthFlowSegment[i].support_progress_status == 20 ||
                                  dataHealthFlowSegment[i].support_progress_status == 30)
                                ) {
                                    this.checkDisabled2C = true;
                                }

                                if (
                                    dataHealthFlowSegment[i].support_flow_seq > 0 &&
                                health_support_flows.support_flow_status == 20 &&
                                (dataHealthFlowSegment[i].support_exec_div == 20 || dataHealthFlowSegment[i].support_exec_div == 30)
                                &&
                                (dataHealthFlowSegment[i].support_progress_status == 20 ||
                                dataHealthFlowSegment[i].support_progress_status == 30 ||
                                dataHealthFlowSegment[i].support_progress_status == 40)
                                ) {
                                    this.checkDisabled2D = true;
                                }
                            }
                        }
                    }
                    this.setCheckUpdateHealthGuidanceReceptions(
                        {
                            checkDisabled2C: this.checkDisabled2C,
                            checkDisabled2D: this.checkDisabled2D,
                            checkDisabled2E: this.checkDisabled2E,
                            checkDisabled2A: this.checkDisabled2A,
                            checkDisabled2B: this.checkDisabled2B,
                        }
                    );
                    this.formData = {
                        out_of_support_patient_ref_level: parseInt(reception.out_of_support_patient_ref_level),
                        client_organization_form_layout_control_id: reception.client_organization_form_layout_control_id,
                        contract_fiscal_year: String(reception.contract_fiscal_year),
                        health_guidance_period: reception.health_guidance_period,
                        continued_support_term: reception.continued_support_term,
                        fractionating_div: parseInt(reception.fractionating_div),
	                    contract_unit_price_01: parseInt(reception.contract_unit_price_01 || 0),
	                    contract_unit_price_02: parseInt(reception.contract_unit_price_02 || 0),
	                    start_pyament_rate: parseInt(reception.start_pyament_rate || 0),
	                    final_eval_payment_rate: parseInt(reception.final_eval_payment_rate || 0),
	                    retire_payment_rate: parseInt(reception.retire_payment_rate || 0),
                        contract: parseInt(reception.health_guidance_contract_div || 0),
                        contract_name: reception.contract_name,
                        health_guidance_contract_div:
                        parseInt(reception.health_guidance_contract_div) ?? "",
                        reception_date: reception.reception_date
                            ? moment(reception.reception_date).format("YYYY-MM-DD")
                            : moment().format("YYYY-MM-DD"),
                        addressee_organiation_id: reception.addressee_organiation_id,
                        contract_coord_organization_id:
                        reception.contract_coord_organization_id,
                        health_guidance_div: parseInt(reception.health_guidance_div),
                        health_support_pattern_id: reception.health_support_pattern_id,
                        payment_agency_id: reception.payment_agency_id,
                        health_guidance_organization_id:
                        reception.health_guidance_organization_id,
                        person_familiy_div: reception.person_familiy_div,
                        first_support_form_id: reception.first_support_form_id,
                        created_at:
                        moment(data.data.created_at).format("YYYY-MM-DD HH:mm:ss") + " ",
                        created_by: data.data.created_by
                            ? data.data.created_by.user_name
                            : null,
                        updated_at:
                        moment(data.data.updated_at).format("YYYY-MM-DD HH:mm:ss") + " ",
                        updated_by: data.data.updated_by
                            ? data.data.updated_by.user_name
                            : null,
                    };
                });
            }
        },
        getHealthGuidanceContract(health_guidance_contract_div) {
            if (
                health_guidance_contract_div &&
        health_guidance_contract_div != undefined
            ) {
                let contractCode = this.listContractCode.filter(
                    (x) => x.value == health_guidance_contract_div
                );
                if (contractCode.length > 0) {
                    return contractCode[0].text;
                }
            }
            return null;
        },
        setupInitialSelect() {
            MedicalDesignatedSubjects.getClientOrganizationListContract().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listContractAgency = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listContractAgency.push({
                            text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                            value: x.id,
                        });
                    });
                }
            );

            MedicalDesignatedSubjects.getCodeMedicalIndication().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listContractCode = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                for (const [key, value] of Object.entries(res.data)) {
                    this.listContractCode.push({
                        text: value,
                        value: parseInt(key),
                    });
                }
            });
            MedicalDesignatedSubjects.getMedicalIndication().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listHealthCode = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                for (const [key, value] of Object.entries(res.data)) {
                    this.listHealthCode.push({
                        text: `${value}`,
                        value: parseInt(key),
                    });
                }
            });
            MedicalDesignatedSubjects.getCodeFamily().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listFamilyCode = [];
                for (const [key, value] of Object.entries(res.data)) {
                    this.listFamilyCode.push({
                        text: value,
                        value: key,
                    });
                }
            });
            MedicalDesignatedSubjects.getHealthSupportPattern().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listSupportPattern = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                res.data.data.map((x) => {
                    this.listSupportPattern.push({
                        text: x.support_pattern_name,
                        value: x.id,
                    });
                });
            });
            MedicalDesignatedSubjects.getHealthSupportForm().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listStatusInitialPreview = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                res.data.data.filter(x => x.map_system_code_04 != "2").forEach((x) => {
                    this.listStatusInitialPreview.push({
                        text: x.support_form_name_disp_02,
                        value: x.id,
                    });
                });
            });
            MedicalDesignatedSubjects.getMedicalGuidanceOrganization().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                const default_health_guidance_organization_id = res.data.data.filter(x => x.default_flag == 1);
                if(default_health_guidance_organization_id.length > 0){
                    this.formData.health_guidance_organization_id = default_health_guidance_organization_id[0].id;
                }
                this.listHealthAgency = [
                    {
                        text: "",
                        value: "",
                        default_flag: "",
                    },
                ];
                res.data.data.map((x) => {
                    this.listHealthAgency.push({
                        text: `[${x.organ_code}] ${x.organ_name}`,
                        value: x.id,
                        default_flag: x.default_flag,
                    });
                });
            });
            MedicalDesignatedSubjects.getPaymentRepresentativeOrganization().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listPaymentAgency = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listPaymentAgency.push({
                            text: `[${x.agency_code}] ${x.agency_name}`,
                            value: x.id,
                        });
                    });
                }
            );
            MedicalDesignatedSubjects.getClientOrganizationListContact().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listDestinationInstitution = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listDestinationInstitution.push({
                            text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                            value: x.id,
                        });
                    });
                }
            );
            MedicalDesignatedSubjects.getCodeFraction().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                for (const [key, value] of Object.entries(res.data)) {
                    this.listfractionating_div.push({
                        text: value,
                        value: parseInt(key),
                    });
                }
            });
        },

        checkReception(action) {
            this.isShowDialogSave = false;
            this.isShowDialogDelete = false;
            this.setIsLoading(true);
            MedicalDesignatedSubjects.checkReceiptMedical(
                [this.dataDetailId]
            ).then((res) => {
                this.setIsLoading(false);
                const { data, status } = res;
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (status !== SUCCESS) return;
                if(data.data.length < 1){
                    this.save();
                }else{
                    var checkSaveReceptions = true;
                    const reception = data.data[0];
                    if (reception.health_support_flows) {
                        this.health_guidance_receptions_id = reception.id;
                        const health_support_flows = reception.health_support_flows[0];
                        if (health_support_flows.health_support_flow_segments) {

                            const dataCheck = this.getCheckUpdateHealthGuidanceReceptions;

                            let dataHealthFlowSegment = _.sortBy(
                                health_support_flows.health_support_flow_segments,
                                "support_flow_seq"
                            );
                            var itemHealthFlowSegment = null;

                            for (let i = 0; i < dataHealthFlowSegment.length; i++) {
                                if (
                                    dataHealthFlowSegment[i].support_progress_status == 10 ||
                                        dataHealthFlowSegment[i].support_progress_status == 20 ||
                                        dataHealthFlowSegment[i].support_progress_status == 30
                                ) {
                                    itemHealthFlowSegment = dataHealthFlowSegment[i];
                                    break;
                                }
                            }

                            for (let i = 0; i < dataHealthFlowSegment.length; i++) {

                                if (
                                    health_support_flows.support_flow_status == 10 &&
                                dataHealthFlowSegment[i].support_exec_div == 10 &&
                                dataHealthFlowSegment[i].support_progress_status == 10
                                ) {
                                    if(!dataCheck.checkDisabled2B){
                                        checkSaveReceptions = false;
                                    }
                                }
                                if (
                                    health_support_flows.support_flow_status == 10 &&
                                dataHealthFlowSegment[i].support_exec_div == 10 &&
                                (dataHealthFlowSegment[i].support_progress_status == 20 ||
                                  dataHealthFlowSegment[i].support_progress_status == 30)
                                ) {
                                    if(!dataCheck.checkDisabled2C){
                                        checkSaveReceptions = false;
                                    }
                                }
                                if (
                                    dataHealthFlowSegment[i].support_flow_seq > 0 &&
                                health_support_flows.support_flow_status == 20 &&
                                (dataHealthFlowSegment[i].support_exec_div == 20 || dataHealthFlowSegment[i].support_exec_div == 30)
                                &&
                                (dataHealthFlowSegment[i].support_progress_status == 20 ||
                                dataHealthFlowSegment[i].support_progress_status == 30 ||
                                dataHealthFlowSegment[i].support_progress_status == 40)
                                ) {
                                    if(!dataCheck.checkDisabled2D){
                                        checkSaveReceptions = false;
                                    }
                                }
                            }

                            if (
                                health_support_flows.support_flow_status == 30 ||
                                health_support_flows.support_flow_status == 40 ||
                                health_support_flows.support_flow_status == 50
                            ) {
                                if(!dataCheck.checkDisabled2E){
                                    checkSaveReceptions = false;
                                }
                            }

                            if (health_support_flows.support_flow_status == 30) {
                                if(!dataCheck.checkDisabled2A){
                                    checkSaveReceptions = false;
                                }
                            }

                            if(checkSaveReceptions){
                                if(action == "save"){
                                    this.save();
                                }else{
                                    this.remove();
                                }
                            }else{
                                var mess = "[E701]入力エラー <br>データ状態が変更されているため、受付取消できません。表示情報を更新してください";
                                if(action == "save"){
                                    mess = "[E701]入力エラー <br>データ状態が変更されているため、保存できません。表示情報を更新してください。";
                                }
                                this.$toast.open({
                                    message: mess,
                                    type: "error",
                                });
                            }
                        }
                    }
                }
            });
        },

        /**
         * 年度変更時に期を変更する
         * @param {*} fiscalYear 年度
         */
        onHandleChangeContractFiscalYear(fiscalYear) {
            const periods = this.getHealthGuidancePeriods(fiscalYear);
            if (periods.length == 1) {
                this.formData.health_guidance_period = periods[0]
            } else if (periods.length == 2) {
                this.formData.health_guidance_period = periods[1];
            }
        }
    },
};
</script>

<style lang="sass" scoped>
@import "../../../styles/common"
.width-250px
    width: 250px
    max-width: 250px
    min-width: 250px
.width-180px
    width: 180px
    max-width: 180px
    min-width: 180px

.sidebar-show
    animation: show 0.5s ease forwards

.sidebar-hidden
    animation: hidden 0.5s ease forwards

@media (max-width: 1025px)
    .navigation
        transform: translate(0%) !important
    .v-navigation-drawer--close
        visibility: unset

@keyframes hidden
    from
        width: var(widthAnimation)
    to
        width: 0

@keyframes show
    from
        width: 0
    to
        width: var(widthAnimation)
.tab-eligible-person-information
    max-height: 85vh
    overflow-y: scroll
</style>
