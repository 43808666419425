<template>
    <v-card>
        <v-container>
	        <v-card-title>{{ $t('common.confirm') }}</v-card-title>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                {{ $t('receiptMedical.copyMedicalReceptionModal', { value: copyItem.text.trim() }) }}
            </v-card-actions>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">{{ $t('common.cancel') }}</button>
                <button type="button" class="btn btn-primary" @click="$emit('confirmDialog')">{{ $t('common.ok') }}</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogCopyMedicalReception",
    props: {
        copyItem: Object
    },
};
</script>

<style scoped>

</style>
