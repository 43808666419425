<template>
    <div class="mgt-10">
        <v-text-field :type="type"
                      :id="id"
                      :value="value"
                      :name="name"
                      :class="className"
                      :placeholder="placeholder"
                      :filled="filled"
                      :rounded="rounded"
                      :error-messages="errorMessage"
                      :disabled="disabled"
                      :solo="solo"
                      :outlined="outlined"
                      :append-icon="appendIcon"
                      :color="color"
                      :prefix="prefix"
                      :suffix="suffix"
                      :append-outer-icon="appendOuterIcon"
                      :prepend-inner-icon="prependInnerIcon"
                      :background-color="backgroundColor"
                      :prepend-icon="prependIcon"
                      :hide-spin-buttons="hideSpinButtons"
                      :autocomplete="autocomplete"
                      @input="handleInput"
                      @change="handleChange"
        >
        </v-text-field>
    </div>
</template>

<script>
import mixins from "@/mixins";

export default {
    name: "InputCommon",
    mixins: [
        mixins
    ],
    props: {
        id: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        className: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            validator: function (value) {
                return ["text", "email", "password", "tel", "number"].includes(value);
            },
            default: "text"
        },
        value: {
            type: [String, Number],
            default: ""
        },
        errorMessages: {
            type: String,
            default: ""
        },
        rounded: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: true
        },
        solo: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        appendIcon: {
            type: String,
            default: undefined
        },
        prependIcon: {
            type: String,
            default: undefined
        },
        backgroundColor: {
            type: String,
            default: undefined
        },
        color: {
            type: String,
            default: undefined
        },
        prefix: {
            type: String,
            default: undefined
        },
        appendOuterIcon: {
            type: String,
            default: undefined
        },
        prependInnerIcon: {
            type: String,
            default: undefined
        },
        suffix: {
            type: String,
            default: undefined
        },
        hideSpinButtons: {
            type: Boolean,
            default: true
        },
        rules: {
            type: [Function, Object],
            default: undefined
        },
	    autocomplete: {
            type: String,
            default: "on"
	    }
    },
    created() {
        this.input = this.value;
        this.error = this.errorMessages;
    },
    data() {
        return {
            input: "",
            error: ""
        };
    },
    computed: {
        errorMessage() {
            if (this.errorMessages) return this.errorMessages;
            if (this.error) return this.error;
            return "";
        }
    },
    watch: {
        value(newVal) {
            if (this.rules) {
                this.validateField(newVal)
            }
            this.input = newVal;
        }
    },
    methods: {
        handleInput(e) {
            this.$emit("input", e);
        },
        handleChange(e) {
            this.$emit("change", e);
        },
        validateField(value) {
            this.rules.validateAt(this.name, {[this.name]: value})
                .then(() => {
                    this.error = "";
                })
                .catch(error => {
                    this.error = error.message;
                });
        }
    }
};
</script>

<style lang="sass" scoped>
    @import "../../styles/variable"
    .text-error
        color: $danger

    .mgt-10
        margin-top: 10px
</style>

<style lang="sass">
    @import "../../styles/variable"
    .v-text-field__suffix
        background: #e7e7e7 !important
        padding: 14px !important
        margin-right: -15px !important
        border-radius: 0px 7px 7px 0px !important
</style>