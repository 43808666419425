<template>
  <v-select
    :items="items"
    :outlined="outlined"
    :filled="filled"
    :multiple="multiple"
    :solo="solo"
    :disabled="disabled"
    :error-messages="errorMessages"
    :full-width="fullWidth"
    :rounded="rounded"
    :value="value"
    :placeholder="placeholder"
    @input="onHandleSelect"
    :label="label"
  >
    <slot />
  </v-select>
</template>

<script>
export default {
    name: "Select",
    props: {
        value: {
            type: [String, Number],
            default: ""
        },
        solo: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        },
        outlined: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: String,
            default: ""
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            select: undefined
        }
    },
    created() {
        this.select = this.value
    },
    watch: {
        select(newVal) {
            this.$emit("onHandleChangeValue", newVal)
        }
    },
    methods: {
        onHandleSelect(e) {
            this.$emit("input", e)
        }
    }
};
</script>

<style scoped>

</style>